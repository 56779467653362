.App {
  text-align: center;
  align-items: center;
  display: flex;
  background-color: #282c34;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

*{
  margin: 0;
  padding: 0;
  outline:0;
  box-sizing: border-box;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  border: #61dafb 2px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.hand{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.espaco{
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #282c34;
  height: 100%;
}

.tabuleiro{
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(7, 82px);
  grid-auto-rows: 82px;
  grid-gap: 10px;
}

.button{
  padding: 8px;
  margin:4px;
  border-radius: 4px;
  background: linear-gradient(to bottom right, #9ec8ad,#61dafb);
  cursor: pointer;
  color: black;
}

.mao{
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(3, 82px);
  grid-auto-rows: 82px;
  grid-gap: 10px;
}
@media (max-width: 650px) {
  .tabuleiro{
    grid-template-columns: repeat(7, 42px);
    grid-auto-rows: 42px;
  }
  .mao{
    grid-template-columns: repeat(3, 42px);
    grid-auto-rows: 42px;
  }
}

.grid-button{
  display: grid;
  grid-template-columns: repeat(2, 63px);
  border: #61dafb 2px solid;
  grid-auto-rows: 63px;
}

.button-mov{
  border-radius: 4px;
  background: linear-gradient(to bottom right, #9ec8ad,#61dafb);
  cursor: pointer;
  color: black;
}